import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["iframe"];

  connect() {
    this.globalSearchInput = document.querySelector("#global-search-box");

    if (this.globalSearchInput) {
      this.globalSearchInput.addEventListener("keypress", this.handleSearchKeyPress.bind(this));
    }

    this.globalSearchResetButton = document.querySelector("#global-search-reset-button");

    if (this.globalSearchResetButton) {
      this.globalSearchResetButton.addEventListener("click", this.resetMap.bind(this));
    }
  }

  disconnect() {
    if (this.globalSearchInput) {
      this.globalSearchInput.removeEventListener("keypress", this.handleSearchKeyPress.bind(this));
    }
  }

  handleSearchKeyPress(event) {
    if (event.key === "Enter") {
      const city = event.target.value.trim();
      if (!city) {
        this.resetMap(); // Reset map to full view of Germany
        return;
      }

      this.fetchCoordinates(city);
    }
  }

  fetchCoordinates(city) {
    const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
      city + ", Germany"
    )}&format=json&limit=1`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          const { lat, lon } = data[0];
          this.updateMap(lat, lon);
        } else {
          alert("City not found");
        }
      })
      .catch((error) => {
        console.error(`Error searching for city ${city}:`, error);
      });
  }

  updateMap(lat, lon, zoom = 12) {
    if (this.hasIframeTarget) {
      this.iframeTarget.contentWindow.postMessage({ lat, lon, zoom }, "*");
    } else {
      console.warn("No iframe target connected to the map-search controller.");
    }
  }

  resetMap() {
    // Coordinates for the center of Germany
    // Latitude and Longitude for Germany's center
    // Appropriate zoom level for a full Germany view
    const germanyCenter = { lat: 51.1657, lon: 10.4515, zoom: 6 }; 

    if (this.hasIframeTarget) {
      this.iframeTarget.contentWindow.postMessage(germanyCenter, "*");
    } else {
      console.warn("No iframe target connected to the map-search controller.");
    }
  }
}
