// app/javascript/controllers/notification_setting_day_select_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { delay: { type: Number, default: 1000 } } // Default delay of 1 second

  initialize() {
    this.timeoutId = null
  }

  toggle(event) {
    event.preventDefault()

    const label = event.currentTarget
    const checkbox = this.element.querySelector(`#${label.getAttribute("for")}`)

    // Toggle the checkbox state
    checkbox.checked = !checkbox.checked

    // Update the label background based on checkbox state
    if (checkbox.checked) {
      label.classList.add("bg-blue-500")
      label.classList.remove("bg-gray-700")
    } else {
      label.classList.add("bg-gray-700")
      label.classList.remove("bg-blue-500")
    }

    // Set a delay before submitting the form
    this.debounceSubmit()
  }

  debounceSubmit() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    this.timeoutId = setTimeout(() => {
      this.submitForm()
    }, this.delayValue)
  }

  submitForm() {
    const form = this.element.closest("form")
    if (form) {
      form.requestSubmit()
    }
  }

  connect() {
    // Initialize day selection
    this.element.querySelectorAll("input[type='checkbox'][name^='notification_setting[daily_stats]']").forEach((checkbox) => {
      const label = this.element.querySelector(`label[for="${checkbox.id}"]`)
      if (checkbox.checked) {
        label.classList.add("bg-blue-500")
        label.classList.remove("bg-gray-700")
      } else {
        label.classList.add("bg-gray-700")
        label.classList.remove("bg-blue-500")
      }
    })

    // Attach change event listener to the "weekly" checkbox
    const weeklyCheckbox = this.element.querySelector("#notification_setting_weekly")
    if (weeklyCheckbox) {
      weeklyCheckbox.addEventListener("change", () => this.submitForm())
    }
  }
}
