import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="global-search"
export default class extends Controller {
  static targets = ["input"]
  static values = {searchUrl: String}

  connect() {
  }

  search(event) {
    if (event.key === "Enter") {
      this.fetchSearch(this.inputTarget.value.trim());
    }
  }

  reset() {
    this.inputTarget.value = "";
    this.fetchSearch("");
  }

  fetchSearch(search) {
    window.location.href = this.searchUrlValue + "?search=" + search;
  }
}
