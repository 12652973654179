import { Application } from "@hotwired/stimulus"

const application = Application.start()

import { registerPolarisControllers } from 'polaris-view-components';
registerPolarisControllers(application)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
